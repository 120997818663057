<template>
  <main id="product">
    <v-card class="mb-5" tile>
      <v-container>
        <v-row>
          <v-col cols="12" lg="6">
            <v-carousel hide-delimiters>
              <v-carousel-item
                v-for="(img, index) in item.imgs"
                :key="index"
                :src="require(`@/assets/${img}`)"
                lazy-src="https://via.placeholder.com/500"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col cols="12" lg="6" class="text-left my-5">
            <v-row justify="center">
              <v-col cols="12">
                <v-sheet elevation="10" rounded="xl">
                  <v-sheet
                    class="pa-3 purple darken-2 font-weight-medium text-left"
                    dark
                    rounded="t-xl"
                  >
                    A1 smoke shop
                  </v-sheet>
                  <v-card-text
                    class="
                      purple--text
                      darken-4
                      text-capitalize
                      font-weight-medium
                    "
                    >{{ item.type }}</v-card-text
                  >
                  <v-card-title class="font-weight-bold display-2">{{
                    item.title
                  }}</v-card-title>
                  <v-card-text v-if="item.price">
                    ${{ item.price }}
                  </v-card-text>
                  <div class="pa-4">
                    <v-chip-group
                      v-if="item.flavors"
                      column
                    >
                      <v-chip
                        class="text-capitalize purple lighten-5 purple--text"
                        v-for="(tag, index) in item.flavors"
                        :key="index"
                      >
                        {{ tag }}
                      </v-chip>
                    </v-chip-group>
                  </div>
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-container class="py-4">
      <div class="text-center display-1 font-weight-bold">
        You may also like
      </div>
      <v-row no-gutters class="py-5" id="new-products">
        <v-col cols="12" lg="3" v-for="(item, index) in trending" :key="index">
          <product-card :item="item" />
        </v-col>
      </v-row>
    </v-container>
  </main>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "product",
  data: () => ({}),
  beforeRouteEnter(to, from, next) {
    next((vm) =>
      vm.getProduct({ title: to.params.name, type: to.params.type })
    );
  },
  beforeRouteUpdate(to, from, next) {
    this.item = null;
    this.getProduct({ title: to.params.name, type: to.params.type });
    next();
  },
  props: {
    name: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  components: {
    "product-card": ProductCard,
  },
  methods: {
    ...mapActions(["getProduct"]),
  },
  computed: {
    ...mapGetters({ trending: "trending", item: "current" }),
  },
};
</script>

<style lang="scss" scoped>
</style>